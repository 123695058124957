import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

// Import Bootstrap frontend framework.
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap';

// Import global styles.
import './assets/scss/main.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
