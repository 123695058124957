<template>
  <div id="home">
    <div class="container py-5">
      <h1 class="text-center mb-3">Australian GST Calculator</h1>

      <div class="card shadow-light col-md-6 offset-md-3 mb-3">
        <div class="card-body">
          <h5 class="card-title">
            <span
              class="btn badge badge-secondary-soft float-right"
              @click="showMoreInfo = !showMoreInfo"
            >
              <small>{{ showMoreInfo ? 'Hide info' : 'More info' }}</small>
            </span>
          </h5>

          <div class="form-group">
            <label for="amount">Amount</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                id="amount"
                v-model="amountInput"
                type="number"
                step="0.01"
                class="form-control"
                inputmode="decimal"
              />
            </div>
          </div>

          <div class="text-center row">
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-success mx-3 p-3"
                @click="calculateGst(amountInput, true)"
              >
                Add GST
              </button>
            </div>
            <div class="col-6">
              <button
                type="submit"
                class="btn btn-warning mx-3 p-3"
                @click="calculateGst(amountInput, false)"
              >
                Subtract GST
              </button>
            </div>
          </div>
        </div>
        <div v-if="showMoreInfo" class="more-info my-3">
          <h5>What is GST?</h5>
          <p>
            Goods and services tax, often known as GST, is a broad-based tax of 10% on most goods,
            services and other items sold or consumed in Australia. GST applies to most Australian
            businesses.
          </p>
          <h5>How do you calculate GST in Australia?</h5>
          <p>GST is calculated at a rate of 10%.</p>
          <p>
            To calculate how much GST was included in the total price, you can divide the total by
            11. Example:
          </p>
          <code>$2,530.00 / 11 = $230.00</code>
          <p class="mt-3">
            To calculate the price excluding GST, you can divide the total by 1.1. Example:
          </p>
          <code>$2,530.00 / 1.1 = $2,300.00</code>
          <p class="mt-3">
            To add GST to the price, you can multiply the total by 1.1. Example:
          </p>
          <code>$2,300.00 x 1.1 = $2,530.00</code>
        </div>
      </div>

      <div class="card shadow-light col-md-6 offset-md-3 mb-3">
        <div class="card-body">
          <h5 class="card-title">
            Result
            <transition name="slide-fade" mode="out-in">
              <span :key="copyBadge" class="badge badge-info-soft float-right">
                <small>{{ copyBadge }}</small>
              </span>
            </transition>
          </h5>

          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              Price (ex GST)
              <span
                class="amount float-right"
                @click="clickAmountHandler('preGstAmount', preGstAmount)"
              >
                $
                <span id="preGstAmount">
                  {{ preGstAmount || '0.00' }}
                </span>
              </span>
            </li>
            <li class="list-group-item">
              GST
              <span class="amount float-right" @click="clickAmountHandler('gstAmount', gstAmount)">
                $
                <span id="gstAmount">
                  {{ gstAmount || '0.00' }}
                </span>
              </span>
            </li>
            <li class="list-group-item">
              Total Price (inc GST)
              <span class="amount float-right" @click="clickAmountHandler('total', total)">
                $
                <span id="total">
                  {{ total || '0.00' }}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <HistoryCard :calc-history="calculatorHistory" @update-calculator="updateCalculator" />
    </div>
  </div>
</template>

<script>
import HistoryCard from '../components/HistoryCard.vue';

export default {
  name: 'Home',

  components: {
    HistoryCard
  },

  data() {
    return {
      showMoreInfo: false,
      amountInput: null,
      gstAmount: 0,
      preGstAmount: 0,
      total: 0,
      copyBadge: 'Click an amount to copy',
      calculatorHistory: []
    };
  },

  methods: {
    calculateGst(amount, addGst) {
      const GST_PERCENT = 0.1;

      // Round input to two decimal places.
      this.amountInput = Math.round(amount * 100) / 100;

      // Calculate GST, pre-GST and total amounts (rounded to two decimal places).
      this.gstAmount = addGst
        ? Math.round(this.amountInput * GST_PERCENT * 100) / 100
        : Math.round((this.amountInput / 11) * 100) / 100;
      this.preGstAmount = addGst
        ? this.amountInput
        : Math.round((this.amountInput - this.gstAmount) * 100) / 100;
      this.total = addGst
        ? Math.round((this.amountInput + this.gstAmount) * 100) / 100
        : this.amountInput;

      // Always display two decimal places (even if 0).
      this.gstAmount = this.gstAmount.toFixed(2);
      this.preGstAmount = this.preGstAmount.toFixed(2);
      this.total = this.total.toFixed(2);

      this.calculatorHistory.unshift({
        amountInput: this.amountInput,
        gstAmount: this.gstAmount,
        preGstAmount: this.preGstAmount,
        total: this.total
      });
    },

    clickAmountHandler(elementId, text) {
      this.selectText(elementId);
      this.copyText(text);
      this.copyBadge = 'Copied!';
      setTimeout(() => {
        this.copyBadge = 'Click an amount to copy';
      }, 3000).bind(this);
    },

    selectText(elementId) {
      const node = document.getElementById(elementId);

      if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
      } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        console.warn('Could not select amount text: Unsupported browser.');
      }
    },

    copyText(text) {
      navigator.clipboard.writeText(text);
    },

    updateCalculator(amountsObj) {
      this.amountInput = amountsObj.amountInput;
      this.gstAmount = amountsObj.gstAmount;
      this.preGstAmount = amountsObj.preGstAmount;
      this.total = amountsObj.total;
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-secondary-soft:hover {
  background-color: rgba(80, 102, 144, 0.15);
  color: #506690;
}

.input-group-text {
  background-color: #fff;
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
  color: #869ab8;
}

.form-control {
  border: 1px solid #f1f4f8;
  border-radius: 0.375rem;
}

.list-group-item:first-of-type {
  border: none;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.amount {
  cursor: pointer;
}
</style>
