<template>
  <div class="card shadow-light col-md-6 offset-md-3 mb-3">
    <div class="card-body">
      <h5 class="card-title">History</h5>

      <ul v-if="calcHistory.length > 0" class="list-group list-group-flush">
        <li
          v-for="(item, index) in filteredHistory"
          :key="index"
          class="list-group-item"
          @click="updateCalculator(item)"
        >
          ${{ item.preGstAmount }} plus GST of ${{ item.gstAmount }} totals ${{ item.total }}
        </li>
      </ul>

      <p v-else class="text-center text-muted">
        <small>No history</small>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryCard',

  props: {
    calcHistory: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  computed: {
    filteredHistory() {
      return this.calcHistory.slice(0, 5);
    }
  },

  methods: {
    updateCalculator(amountsObj) {
      this.$emit('update-calculator', amountsObj);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-muted {
  color: #869ab8 !important;
}

.list-group-item {
  color: #506690;
  cursor: pointer;
}

.list-group-item:hover {
  color: #335eea;
}
</style>
